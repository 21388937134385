import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import BottomLayout from "@/layout/BottomLayout";
import Header from "@components/head";

import {
  Text,
  List,
  ListItem,
  Flex,
  Center,
  Link,
  VStack,
  Box,
} from "@chakra-ui/react";
import TopCardWrap from "@/content/product/TopCardWrap";
import GraphicText from "@/layout/GraphicText";
import { HOST_URL } from "@/utils/constant";

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/product/zoom`}
      cover="https://a.storyblok.com/f/167495/1896x687/47eab6c81b/productzoombanner.png"
      title="Airgram | Turn Sync Zoom Meetings into Async Collaboration"
      description="Multilingual Transcription Tool that Suits Your Zoom Calls Anytime. HD video recording, Real-time transcripts with speaker detection, collaborative notes with timestamps."
    />
  );
};

const { useState } = React;

const IndexPage = () => {
  return (
    <Layout
      bottomLayout={
        <BottomLayout
          buttonId="product_zoom_bottom_cta"
          buttonText="Try for free"
          buttonLink="https://app.airgram.io/signup"
          description="Bring your Zoom meeting experience to the next level"
          signGtmId="web_sign_up_cta_btn_platform_zoom"
        />
      }
    >
      <VStack>
        <TopCardWrap
          title="Plan your Zoom with ease, document with joy, and leave with clarity."
          subtext="Record, transcribe and document the whole conversation. Create clips to extract highlights efficiently."
        >
          <StaticImage
            loading="lazy"
            alt="Zoom"
            src="https://a.storyblok.com/f/167495/1896x687/47eab6c81b/productzoombanner.png"
          />
        </TopCardWrap>

        <Flex
          gap={{ base: "40px", md: "80px", lg: "120px" }}
          paddingBottom="64px"
          flexDirection="column"
        >
          <GraphicText
            slot={
              <StaticImage
                loading="lazy"
                alt="Google Meet Agenda"
                src="https://a.storyblok.com/f/167495/800x509/4bd5e1d8a1/productzoomautojoin800.png"
              />
            }
            title="Auto join Zoom meetings"
          >
            <Flex gap="12px" flexDir="column">
              <Text>
                Never worry about missing out on any events. Airgram Bot
                automatically joins all or particular Zoom meetings on your
                Google Calendar. No more manual operation.
              </Text>

              <Text>
                Transcribing an instant Zoom call is also possible with just a
                click or shortcut key.
              </Text>

              <List>
                <ListItem>🔹 Automate recording and transcription</ListItem>
                <ListItem>🔹 Quick start instant Zoom calls</ListItem>
              </List>

              <Text>
                👉 Learn to
                <Link
                  href="https://help.airgram.io/en/articles/5972007-how-to-auto-join-zoom-meetings-with-ai-assistant"
                  target="_blank"
                  color="#9f2aec"
                  width="62px"
                  lineHeight="52px"
                  marginLeft="10px"
                  textDecoration="underline"
                >
                  auto join Zoom
                </Link>
              </Text>
            </Flex>
          </GraphicText>

          <GraphicText
            slot={
              <StaticImage
                loading="lazy"
                alt="Google Meet Transcript"
                src="https://a.storyblok.com/f/167495/1100x700/e1b2d61db7/productzoomtranscript1.png"
              />
            }
            title="Live transcribe meetings"
            placement="right"
          >
            <Flex flexDir="column" gap="12px">
              <Text>
                Get a real-time transcript instantly as the call is taking
                place. Search meeting notes and transcripts to quickly locate
                key information.
              </Text>

              <List>
                <ListItem>🔹 8 transcription languages</ListItem>
                <ListItem>🔹 Speaker detection</ListItem>
                <ListItem>🔹 Downloadable 1080P video recordings</ListItem>
              </List>
            </Flex>
          </GraphicText>

          <GraphicText
            slot={
              <StaticImage
                loading="lazy"
                alt="Google Meet Collaborative Notes"
                src="https://a.storyblok.com/f/167495/1100x700/37e14b8806/productzoomcollaborativenotes1.png"
              />
            }
            title="Edit & share meeting notes"
          >
            <Flex flexDir="column" gap="12px">
              <Text>
                Timestamped notes provides you with context while reviewing
                meetings. Create action items to track the completion of a
                project.
              </Text>

              <Text>
                Keep everyone updated on the vital matter. Extract key
                highlights from your own meeting notes with clips; sync the
                notes to Slack, Notion, Google Docs, or Microsoft Word.
              </Text>

              <List>
                <ListItem>🔹 Timestamped notes, action items</ListItem>
                <ListItem>🔹 Create and share clips</ListItem>
                <ListItem>
                  🔹 Export to Slack, Notion, Google Docs, and MS Word.
                </ListItem>
              </List>
            </Flex>
          </GraphicText>
        </Flex>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
