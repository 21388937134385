import React, { memo } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Text, Center } from "@chakra-ui/react";
import styled from "@emotion/styled";

const CardWrapper = styled(Box)`
  @media screen and (max-width: 1000px) {
    .mc-product--subtext {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .mc-product--content {
      top: -40px;
      .mc-product--subtext {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .mc-product--content {
      top: -20px;
      padding-left: 12px;
      padding-right: 12px;
      .mc-product--subtext {
        font-size: 14px;
      }
    }
  }
`;

interface ITopCardWrap {
  title: string;
  subtext: string;
  children: any;
}
const TopCardWrap: React.FC<ITopCardWrap> = ({ title, subtext, children }) => {
  return (
    <CardWrapper className="mc-product--card" width="100%">
      <Box overflow="hidden" borderRadius="12px">
        {children}
      </Box>

      <Box
        className="mc-product--content"
        top="-85px"
        position="relative"
        color="#220247"
        padding="24px"
        textAlign="center"
        boxShadow="0 4px 12px 0 rgb(0 0 0 / 10%)"
        borderRadius="12px"
        backgroundColor="white"
      >
        <Box w="80%" as="h2" margin="auto" fontSize="32px" fontWeight="600">
          {title}
        </Box>

        <Box className="mc-product--subtext" fontSize="18px" marginTop="24px">
          {subtext}
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default memo(TopCardWrap);
